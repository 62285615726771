var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CampaignHeader", {
        ref: "campaignHeader",
        attrs: {
          "campaign-id": _vm.getCampaignId(),
          "mailing-item-id": _vm.mailingItemId,
          "step-count": _vm.stepCount
        },
        on: {
          setCampaign: _vm.setCampaign,
          setMailing: _vm.setMailing,
          cancelStep: _vm.cancelStep
        }
      }),
      _c(
        "v-stepper",
        {
          model: {
            value: _vm.stepCount,
            callback: function($$v) {
              _vm.stepCount = $$v
            },
            expression: "stepCount"
          }
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "div",
                {
                  staticClass: "px-6 pt-5 pb-2 text-h6",
                  staticStyle: { width: "298px" }
                },
                [
                  _c("v-text-field", {
                    staticClass: "input-max-width",
                    attrs: {
                      "error-messages": _vm.mailingNameErrors,
                      dense: "",
                      label: "Mailing Name",
                      "data-wsc-autocreate": "true",
                      "data-wsc-lang": "en_US"
                    },
                    on: {
                      input: function($event) {
                        return _vm.$v.mailingName.$touch()
                      },
                      blur: function($event) {
                        _vm.$v.mailingName.$touch()
                        if (_vm.mailingName) {
                          _vm.setMailingValue("mailingName")
                        }
                      }
                    },
                    model: {
                      value: _vm.mailingName,
                      callback: function($$v) {
                        _vm.mailingName = $$v
                      },
                      expression: "mailingName"
                    }
                  })
                ],
                1
              ),
              _c("v-divider", {
                staticClass: "mx-0",
                attrs: { vertical: "", inset: "" }
              }),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.stepCount > _vm.getStepVal("emailSetup"),
                    step: _vm.getStepVal("emailSetup"),
                    editable: _vm.emailConfigOn !== null
                  },
                  on: {
                    click: function($event) {
                      _vm.goToStep(_vm.getStepVal("emailSetup"))
                    }
                  }
                },
                [_vm._v("\n        Email Setup\n      ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.stepCount > _vm.getStepVal("testContent"),
                    step: _vm.getStepVal("testContent"),
                    editable: _vm.testedOn !== null
                  }
                },
                [_vm._v("\n        Test\n      ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.stepCount > _vm.getStepVal("scheduleMailing"),
                    step: _vm.getStepVal("scheduleMailing")
                  }
                },
                [_vm._v("\n        Schedule\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                {
                  staticClass: "pa-3",
                  attrs: { step: _vm.getStepVal("emailSetup") }
                },
                [
                  _vm.getStepVal("emailSetup") == _vm.stepCount
                    ? _c("EmailSetup", {
                        key: _vm.getStepKey("emailSetup"),
                        ref: "emailSetup",
                        attrs: { "step-val": _vm.getStepVal("emailSetup") },
                        on: {
                          cancelStep: _vm.cancelStep,
                          advanceFromStep: _vm.advanceFromStep
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-stepper-content",
                {
                  staticClass: "pa-3",
                  attrs: { step: _vm.getStepVal("testContent") }
                },
                [
                  _vm.getStepVal("testContent") == _vm.stepCount
                    ? _c("TestContent", {
                        key: _vm.getStepKey("testContent"),
                        ref: "testContent",
                        attrs: { "step-val": _vm.getStepVal("testContent") },
                        on: {
                          cancelStep: _vm.cancelStep,
                          advanceFromStep: _vm.advanceFromStep
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-stepper-content",
                {
                  staticClass: "pa-3",
                  attrs: { step: _vm.getStepVal("scheduleMailing") }
                },
                [
                  _vm.getStepVal("scheduleMailing") == _vm.stepCount
                    ? _c("ScheduleMailing", {
                        key: _vm.getStepKey("scheduleMailing"),
                        ref: "scheduleMailing",
                        attrs: {
                          "step-val": _vm.getStepVal("scheduleMailing")
                        },
                        on: {
                          cancelStep: _vm.cancelStep,
                          advanceFromStep: _vm.advanceFromStep
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.finalDialog,
            callback: function($$v) {
              _vm.finalDialog = $$v
            },
            expression: "finalDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _vm._v(
                  "\n        Mailing Complete! Would you like to continue with this Campaign?\n      "
                )
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "pa-5", staticStyle: { "max-height": "75vh" } },
                [
                  _c("CampaignDashboardItem", {
                    attrs: {
                      campaign: _vm.campaign,
                      "current-mailing-id": _vm.mailingItemId
                    }
                  })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "tertiary" },
                      on: {
                        click: function($event) {
                          return _vm.closeFinalDialog()
                        }
                      }
                    },
                    [_vm._v("\n          No, Take me to Dashboard\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }