<template>
  <div>
    <CampaignHeader
      ref="campaignHeader"
      :campaign-id="getCampaignId()"
      :mailing-item-id="mailingItemId"
      :step-count="stepCount"
      @setCampaign="setCampaign"
      @setMailing="setMailing"
      @cancelStep="cancelStep"
    />
    <v-stepper v-model="stepCount">
      <v-stepper-header>
        <div
          class="px-6 pt-5 pb-2 text-h6"
          style="width: 298px"
        >
          <v-text-field
            v-model="mailingName"
            :error-messages="mailingNameErrors"
            dense
            label="Mailing Name"
            data-wsc-autocreate="true"
            data-wsc-lang="en_US"
            class="input-max-width"
            @input="$v.mailingName.$touch()"
            @blur="$v.mailingName.$touch(); if (mailingName) setMailingValue('mailingName')"
          />
        </div>

        <v-divider
          vertical
          inset
          class="mx-0"
        />

        <v-stepper-step
          :complete="stepCount > getStepVal('emailSetup')"
          :step="getStepVal('emailSetup')"
          :editable="emailConfigOn !== null"
          @click="goToStep(getStepVal('emailSetup'))"
        >
          Email Setup
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="stepCount > getStepVal('testContent')"
          :step="getStepVal('testContent')"
          :editable="testedOn !== null"
        >
          Test
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="stepCount > getStepVal('scheduleMailing')"
          :step="getStepVal('scheduleMailing')"
        >
          <!-- :editable="scheduledOn !== null" -->
          Schedule
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('emailSetup')"
        >
          <EmailSetup
            v-if="getStepVal('emailSetup') == stepCount"
            ref="emailSetup"
            :key="getStepKey('emailSetup')"
            :step-val="getStepVal('emailSetup')"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>

        <v-stepper-content
          class="pa-3"
          :step="getStepVal('testContent')"
        >
          <TestContent
            v-if="getStepVal('testContent') == stepCount"
            ref="testContent"
            :key="getStepKey('testContent')"
            :step-val="getStepVal('testContent')"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>

        <v-stepper-content
          class="pa-3"
          :step="getStepVal('scheduleMailing')"
        >
          <ScheduleMailing
            v-if="getStepVal('scheduleMailing') == stepCount"
            ref="scheduleMailing"
            :key="getStepKey('scheduleMailing')"
            :step-val="getStepVal('scheduleMailing')"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog
      v-model="finalDialog"
      persistent
    >
      <v-card>
        <v-card-title
          primary-title
        >
          Mailing Complete! Would you like to continue with this Campaign?
        </v-card-title>
        <v-divider />
        <v-card-text
          class="pa-5"
          style="max-height: 75vh"
        >
          <CampaignDashboardItem
            :campaign="campaign"
            :current-mailing-id="mailingItemId"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="tertiary"
            @click="closeFinalDialog()"
          >
            No, Take me to Dashboard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { wscMixin } from '@/shared/mixins/general'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'MailingCampaign',

  components: {
    CampaignDashboardItem: () => import('@/views/pages/components/mailing/CampaignDashboardItem'),
    CampaignHeader: () => import('@/views/pages/components/mailing/CampaignHeader'),
    EmailSetup: () => import('./EmailSetup'),
    TestContent: () => import('./TestContent'),
    ScheduleMailing: () => import('./ScheduleMailing')
  },

  mixins: [wscMixin],

  props: {
    mailingItemId: {
      type: String,
      required: true
    }
  },

  validations: {
    mailingName: {
      required
    }
  },

  data () {
    return {
      stepCount: -1,
      campaign: null,
      finalDialog: false,
      routes: [
        {
          path: 'emailSetup',
          ref: 'emailSetup',
          api: 'emailConfigOn',
          stepVal: 1,
          key: 0
        },
        {
          path: 'test',
          ref: 'testContent',
          api: 'testedOn',
          stepVal: 2,
          key: 0
        },
        {
          path: 'schedule',
          ref: 'scheduleMailing',
          api: 'scheduledOn',
          stepVal: 3,
          key: 0
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('mailing', ['getCampaignId']),
    ...mapState('mailing', ['emailConfigOn', 'testedOn', 'scheduledOn']),
    mailingName: {
      get () {
        return this.$store.getters['mailing/getMailingName']()
      },
      set (value) {
        this.$store.commit('mailing/setMailingValue', { prop: 'mailingName', value })
      }
    },
    mailingNameErrors () {
      const errors = []
      if (!this.$v.mailingName.$dirty) return errors
      !this.$v.mailingName.required &&
        errors.push('Name is required')
      return errors
    },
    currentMailing () {
      return this.campaign && this.campaign.mailings ? this.campaign.mailings.find(x => x.id === this.mailingItemId) : null
    },
    taSelectionRequired () {
      return !this.campaign || (!this.campaign.targetAudienceId && this.stepCount === this.getStepVal('scheduleMailing'))
    }
  },

  watch: {
    '$route': function () {
      this.setMailing(true)
    },
    '$route.params.mailingItemId': function (mailingItemId) {
      this.finalDialog = false
      this.setMailing(true)
    },
    stepCount (newValue, oldValue) {
      this.$refs.campaignHeader.setCampaign()
      if (oldValue && oldValue !== -1 && oldValue !== 4) {
        this.$refs[this.getStep(oldValue).ref].destroy()
      }
      this.$vuetify.goTo(0)
      if (newValue === 4) {
        this.finalDialog = true
        return
      }
      this.finalDialog = false
      this.getStep(newValue).key++
    }
  },

  created () {
    this.setMailing(false)
  },

  methods: {
    ...mapActions('mailing', ['setMailingStepValue']),
    async setMailing (samePage) {
      let resp = await this.$store.dispatch('mailing/setMailing', { mailingItemId: this.mailingItemId })
      this.initStep(resp, samePage)
    },
    async setCampaign (campaign) {
      Vue.set(this, 'campaign', campaign)

      // check if campaign.isCanceled and redirect to dashboard with snackbar error
      if (this.campaign.isCanceled) {
        this.$store.commit('setGlobalSnackbar', {
          message: 'This campaign is canceled and cannot be modified',
          color: 'error',
          isPageChange: true
        })
        this.$router.push({ name: 'CampaignDashboard' })
      }

      if (this.taSelectionRequired) {
        this.showTADialog = true
      }
    },
    initStep (mailing, samePage) {
      if (!mailing.emailConfigOn) {
        this.stepCount = this.getStepVal('emailSetup')
      } else if (!mailing.testedOn) {
        this.stepCount = this.getStepVal('testContent')
      } else if (!mailing.scheduledOn) {
        this.stepCount = this.getStepVal('scheduleMailing')
      } else {
        this.stepCount = 4
      }
      if (samePage) {
        this.$refs.campaignHeader.setCampaign()
        this.getStep(this.stepCount).key++
      }
    },
    getStepVal (stepRef) {
      return this.routes.find(x => x.ref === stepRef).stepVal
    },
    getStepKey (stepRef) {
      return this.routes.find(x => x.ref === stepRef).key
    },
    getStep (stepVal) {
      return this.routes.find(x => x.stepVal === stepVal)
    },
    async cancelStep (stepNum) {
      this.stepCount = stepNum - 1
      await this.setMailingStepValue({ prop: this.getStep(this.stepCount).api, flag: 'false' })
    },
    async advanceFromStep (stepNum) {
      await this.setMailingStepValue({ prop: this.getStep(stepNum).api, flag: 'true' })
      if (stepNum + 1 === 4) {
        this.$store.commit('setGlobalSnackbar', {
          message: `Mailing successfully scheduled!`,
          color: 'success',
          isPageChange: true
        })
      }
      this.stepCount = stepNum + 1
    },
    closeFinalDialog () {
      this.finalDialog = false
      this.$router.push({ name: 'CampaignDashboard' })
    },
    goToStep (stepNum) {
      if (this.stepCount > stepNum) {
        for (var backward = this.stepCount - 1; backward >= stepNum; backward--) {
          this.setMailingStepValue({ prop: this.getStep(backward).api, flag: 'false' })
        }
      } else if (this.stepCount < stepNum) {
        for (var forward = this.stepCount; forward < stepNum; forward++) {
          this.setMailingStepValue({ prop: this.getStep(forward).api, flag: 'true' })
        }
      }
      this.stepCount = stepNum
    },
    setMailingValue (prop) {
      this.$store.dispatch('mailing/setMailingValue', { prop })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-progress-linear {
  display: inline-block;
  vertical-align: middle;
}
</style>
